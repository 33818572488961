<template>
  <div>
    <div class="createText">
      标签设置
    </div>

    <div class="label flex flex-center column">
      <div
        v-for="(item, index) in label"
        :key="index"
        class="flex flex-center column"
      >
        <div class="labelView flex flex-center">
          <div>{{ item.group_name }}</div>
          <div class="labelView-right">
            <img
              class="labelView-addIcon"
              @click="addLabel(item)"
              src="../assets/imgs/label-add.png"
              alt=""
            />
          </div>
        </div>
        <div class="labelContent flex wrap">
          <div
            class="labelContent-item"
            :class="val.is_have == 1 ? 'selectItem' : ''"
            @click="selectItem(index, idx)"
            v-for="(val, idx) in item.tags"
            :key="idx"
          >
            {{ val.tag_name }}
          </div>
        </div>
      </div>
    </div>

    <!-- 添加标签 -->
    <div class="layer" v-if="layer == 1">
      <div class="layerBox">
        <div class="flex flex-center">
          <div>标签名：</div>
          <input
            type="text"
            placeholder="请输入您的标签名"
            v-model="tag_name"
          />
        </div>
        <div class="flex flex-center" style="justify-content: flex-end;">
          <div class="layerBox-btn flex-item_center" @click="layer = 0">
            取消
          </div>
          <div class="layerBox-confirm flex-item_center" @click="createLabel()">
            确定
          </div>
        </div>
      </div>
    </div>

    <!-- 底部固定 -->
    <div class="bottom fixed flex">
      <div class="bottom-add flex-item_center" @click="save()">
        保存
      </div>
    </div>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";
import { validate } from "json-schema";

export default {
  data() {
    return {
      label: [],
      layer: 0,
      tag_name: "",
      contact_tag_group_id: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 保存
    async save() {
      var arr = [];
      this.label.forEach((item) => {
        item.tags.forEach((val) => {
          val.is_have == 1 ? arr.push(val.tag_id) : "";
        });
      });
      console.log("arr", arr);
      if (arr.length == 0) {
        Toast("请先选择标签");
        return false;
      }

      let res = await request._saveLabel({
        userid: localStorage.getItem("userid"),
        id: this.$route.query.id,
        edit_tag: arr.join(","),
      });
      if (_.toInteger(res.code) === 1) {
        Toast.success("添加成功");
        this.$router.back();
      } else {
        Toast(res.msg);
      }
    },
    selectItem(index, childIndex) {
      if (this.label[index].tags[childIndex].is_have == 0) {
        this.label[index].tags[childIndex].is_have = 1;
      } else {
        this.label[index].tags[childIndex].is_have = 0;
      }
    },
    async createLabel() {
      if (this.tag_name == "") {
        Toast("请填写标签名");
        return false;
      }
      let res = await request._addLabel({
        userid: localStorage.getItem("userid"),
        tag_name: this.tag_name,
        contact_tag_group_id: this.contact_tag_group_id,
      });
      console.log("添加标签=", res);
      if (_.toInteger(res.code) === 1) {
        Toast.success("添加成功");
        this.layer = 0;
        this.getList();
      } else {
        Toast(res.msg);
      }
    },
    async getList() {
      let res = await request._getLabelList({
        id: this.$route.query.id,
      });
      console.log("标签 res", res);
      if (_.toInteger(res.code) === 1) {
        let result = res.data;
        result.forEach((item) => {
          item.tags.forEach((val) => {
            val.select = false;
          });
        });
        this.label = result;
      }
    },
    addLabel(item) {
      this.contact_tag_group_id = item.group_id;
      this.layer = 1;
    },
  },
};
</script>

<style scoped>
.createText {
  font-size: 40px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  padding: 43px 0 36px 30px;
}

.label {
  width: 690px;
  /* height: 1047px; */
  margin: 0 auto;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  padding-bottom: 150px;
}

.labelView {
  padding: 30px 0;
  width: 630px;
  border-bottom: 1px solid #d6e5ee;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  justify-content: space-between;
}

.labelView-right {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
}

.labelContent {
  width: 630px;
  padding: 28px 0 66px 0;
}

.labelContent-item {
  padding: 6px 39px;
  background: #f1f3ff;
  border-radius: 4px 4px 4px 4px;
  margin: 0 13px 26px 0;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
}

.bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.labelView-addIcon {
  width: 80px;
  height: 33px;
}

.bottom-add {
  width: 670px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 15px;
}

.layer {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.8);
}

.layerBox {
  background: #fff;
  width: 600px;
  padding: 60px 30px 70px 30px;
  border-radius: 18px;
  margin: 350px auto;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.layerBox input {
  outline: none;
  border-radius: 0;
  font-size: 28px;
  border: none;
  border-bottom: 1px solid #d6e5ee;
}

.layerBox-btn {
  width: 126px;
  height: 46px;
  border: 1px solid #0558f4;
  margin: 50px 0 0 0;
  color: #0558f4;
  border-radius: 4px 4px 4px 4px;
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
}

.layerBox-confirm {
  width: 126px;
  height: 46px;
  background: #0558f4;
  color: #fff;
  margin: 50px 0 0 20px;
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  border-radius: 4px 4px 4px 4px;
}

.selectItem {
  color: #0558f4;
  border: 1px solid #0558f4;
}
</style>
